import { useEffect, useState } from "react";

export default function ThemeToggle() {
    const [dark, setDark] = useState(true);

    useEffect(() => {
        document.documentElement.classList.toggle("dark", dark);
    }, [dark]);

    return (
        <button
            onClick={() => setDark(!dark)}
            className="absolute top-4 right-4 px-3 py-1.5 text-sm rounded-xl bg-white/10 text-white hover:bg-white/20 transition z-50"
        >
            {dark ? "☀️ Light" : "🌙 Dark"}
        </button>
    );
}
