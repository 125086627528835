import { useState, useEffect } from "react";
import { motion } from "framer-motion";

function TraceCountdown(props) {
    const [count, setCount] = useState(props.seconds);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    if (count === 0) return null;

    return (
        <section className="snap-start mt-4 flex flex-col items-center justify-center font-sans relative">

                <motion.div
                    key={count}
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center gap-2 text-sm italic text-gray-500 dark:text-gray-400"
                    style={{ scale: "1" }} // prevent layout shift
                >
                    <span className="w-2 h-2 rounded-full bg-blue-500 animate-pulse" />
                    Waiting for Tempo to finish collecting full trace… ({count}s)
                </motion.div>
        </section>
    );
}

export default TraceCountdown;
