import {useEffect, useRef, useState} from "react";
import { motion } from "framer-motion";
import { Card, CardContent } from "./components/ui/card.tsx";
import { Button } from "./components/ui/button.tsx";
import InfraDiagram from "./components/InfraDiagram.tsx";
import ThemeToggle from "./components/ui/ThemeToggle.tsx";
import TraceTimeline from "./components/TraceTimeline.tsx";
import UptimeDisplay from "./components/ui/uptimeDisplay.tsx";
import { context, propagation } from '@opentelemetry/api';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { Resource } from '@opentelemetry/resources';
import { trace } from '@opentelemetry/api';
import ResumeSection from "./components/ResumeSection.tsx";
import useScrollSnap from "./useScrollSnap";
import WarStoriesSection from "./components/WarStoriesSection.tsx";
import TraceCountdown from "./components/ui/TraceCountdown.tsx";

// eslint-disable-next-line react-hooks/rules-of-hooks


const resource = Resource.default().merge(
    new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'frontend',
        [SemanticResourceAttributes.SERVICE_VERSION]: '0.1.0',
    })
);

const provider = new WebTracerProvider({ resource });

const exporter = new OTLPTraceExporter({
    url: "/v1/traces",
    headers: () => {
        const span = trace.getActiveSpan();
        return span
            ? { traceid: span.spanContext().traceId }
            : {};
    },
});

provider.addSpanProcessor(new SimpleSpanProcessor(exporter));

provider.register();


registerInstrumentations({
    instrumentations: [
        new FetchInstrumentation({
            propagateTraceHeaderCorsUrls: [/.*/],
        })
    ]
});

const tracer = trace.getTracer('frontend');

export default function PortfolioPage() {
    const [metrics, setMetrics] = useState(null);
    const [traceSummary, setTraceSummary] = useState(null);
    const [traceLoading, setTraceLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [ResumeVisible,setResumeVisible] = useState(false);




    useEffect(() => {
        fetchMetrics();
    }, []);

    const toggleResumeVisibility = () => {
        setResumeVisible(!ResumeVisible);
    };
    async function fetchMetrics() {
        const span = tracer.startSpan("Frontend User Trigger");
        const spanCtx = trace.setSpan(context.active(), span); // manual context
        const headers = {};
        //propagation.inject(spanCtx, headers);
        propagation.inject(context.active(), headers);
        setLoading(true);
        setTraceLoading(true);


        await context.with(trace.setSpan(context.active(), span), async () => {
            propagation.inject(context.active(), headers);
            try {
                const res = await fetch("/api/metrics", {
                    method: "GET",
                    headers,
                });

                const data = await res.json();
                setMetrics(data);

                setTimeout(async () => {
                    try {
                        const summaryRes = await fetch(`/api/trace-summary/${data.trace_id}`);
                        const summaryData = await summaryRes.json();
                        console.log(summaryData);
                        setMetrics(prev => {
                            if (!prev) return prev;
                            const backendSpan = summaryData.find(s => s.service === 'sre-portfolio-backend' && s.name.includes('/api/metrics'));
                            return {
                                ...prev,
                                http_status: backendSpan?.attributes?.find(a => a.key === 'http.status_code')?.value?.intValue ?? 200,
                                latency_ms: backendSpan ? (backendSpan.end - backendSpan.start) / 1_000_000 : prev.latency_ms,
                            };
                        });

                        setTraceSummary(summaryData);
                    } catch (err) {
                        console.error("Error fetching trace summary:", err);
                    } finally {
                        setTraceLoading(false);
                    }
                }, 9000);
            } catch (err) {
                console.error("Error fetching metrics:", err);
                setTraceLoading(false);
            } finally {
                setLoading(false);
            }
        });
        span.end();

    }
    const scrollContainerRef = useRef(null);
    useScrollSnap(scrollContainerRef, {
        snapDestinationY: "100%",
        timeout: 100,
        duration: 300,
        threshold: 0.1,
    });
    return (
        <div
            ref={scrollContainerRef}
            className="h-screen overflow-y-scroll"
            style={{ scrollSnapType: "y mandatory" }}
        >

            <main className="min-h-screen bg-gradient-to-br from-gray-100 to-blue-100 dark:from-gray-950 dark:to-blue-950 text-black dark:text-white p-6 flex flex-col items-center justify-center font-sans relative overflow-hidden transition-colors duration-300">
            <ThemeToggle />
                <section className="h-screen snap-start flex flex-col items-center justify-center font-sans relative">

            <UptimeDisplay/>
            <motion.h1
                className="text-5xl md:text-7xl font-extrabold mb-6 tracking-tight"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}

            >

                Observability. Self-Hosted.
            </motion.h1>

            <motion.p
                className="text-lg md:text-xl text-gray-700 dark:text-gray-300 mb-10 text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.8 }}
            >
                Made with ❤️ and hosted by me on a Raspberry Pi Kubernetes cluster.
            </motion.p>


            <Button onClick={() => setShowResults(true)} className="mb-8 text-base px-6 py-3" bounce={true}>
                {loading ? "Fetching..." : "Check your request"}
            </Button>



            {showResults && metrics && (
                <Card className="w-full max-w-lg bg-white/60 dark:bg-white/5 backdrop-blur-sm border border-white/30 dark:border-white/10 shadow-lg">
                    <CardContent className="space-y-4">
                        <p className="text-lg"><strong>Status:</strong> <span className="text-green-600 dark:text-green-400">{metrics.http_status ?? "loading"}</span></p>
                        <p className="text-lg"><strong>Latency:</strong> <span className="text-yellow-600 dark:text-yellow-300">{metrics.latency_ms?.toFixed?.(2) ?? "..."} ms</span></p>
                        <p className="text-lg"><strong>Trace ID:</strong> <span className="text-blue-600 dark:text-blue-400 font-mono">{metrics.trace_id?.slice(0, 8)}...</span></p>
                        <p className="text-lg"><strong>Timestamp:</strong> <span className="text-gray-700 dark:text-gray-300">{new Date(metrics.timestamp).toLocaleString()}</span></p>

                        {traceLoading && (
                            <p className="text-sm italic text-gray-500 dark:text-gray-400">Enriching trace details…</p>
                        )}

                        {traceSummary && traceSummary.length > 0 && (
                            <>
                                <p className="text-lg"><strong>Trace Duration:</strong> <span className="text-purple-600 dark:text-purple-300">{((traceSummary.at(-1).end - traceSummary[0].start) / 1_000_000).toFixed(2)} ms</span></p>
                                <p className="text-lg"><strong>HTTP Code:</strong> <span className="text-green-600 dark:text-green-400">{metrics.http_status}</span></p>
                            </>
                        )}
                    </CardContent>
                </Card>
            )}


                <motion.div
                className="mt-10 text-center text-gray-500 dark:text-gray-400 text-sm italic"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.5, duration: 1 }}>
                psst... you just triggered a live request to my cluster.
            </motion.div>
                </section>

                {traceLoading && (
                        <TraceCountdown seconds={6}/>

                    )}
                {traceSummary && traceSummary.length > 0 && (
                        <div className="mt-8 w-full max-w-4xl" >
                            <TraceTimeline spans={traceSummary} />
                        </div>

                )}

                <div className="mt-20 max-w-4xl w-full content-center">
                <motion.div
                    className="bg-white/60 dark:bg-white/5 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-white/30 dark:border-white/10"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.2, duration: 0.8 }}
                >
                    <section className="snap-start">

                    <h2 className="text-3xl font-bold mb-6">How it works</h2>
                    <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 space-y-3 text-base">
                        <li>Request hits a load balancer in my Pi cluster</li>
                        <li>Python backend records metrics + traces</li>
                        <li>Data flows into Loki, Tempo, and Prometheus</li>
                        <li>Frontend displays sanitized trace data back to you</li>
                    </ul>
                    </section>

                </motion.div>
                    <section className="snap-start">

                    <motion.div
                    className="mb-8 text-base px-6 py-3 flex flex-col items-center justify-center"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.6, duration: 0.8 }}
                >

                    <Button bounce={false} onClick={toggleResumeVisibility}>

                        Let’s build systems together
                    </Button>
                </motion.div>
                    </section>


                    <section className="snap-start min-h-screen" hidden={!ResumeVisible}>

                    <motion.div
                        hidden={!ResumeVisible}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: ResumeVisible ? 1 : 0 }}
                        transition={{ duration: 0.4 }}
                        className="w-full mt-4"
                    >

                        <ResumeSection />
                </motion.div>
                    </section>
                        <section className="snap-start ">

                        <InfraDiagram className="mt-12" />
                    </section>


            </div>
                <section className="snap-start">

                <WarStoriesSection />
                </section>



            </main>
        </div>
    );
}
