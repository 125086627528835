import { motion } from "framer-motion";

export default function TraceTimeline({ spans }) {
    if (!spans || spans.length === 0) return null;

    const minStart = Math.min(...spans.map(s => s.start));
    const maxEnd = Math.max(...spans.map(s => s.end));
    const totalDuration = maxEnd - minStart;

    return (
        <div className="mt-10 space-y-4 w-full">
            <h3 className="text-2xl font-semibold mb-2">Trace Waterfall</h3>
            {spans.map((span, i) => {
                const offsetPercent = ((span.start - minStart) / totalDuration) * 100;
                const widthPercent = ((span.end - span.start) / totalDuration) * 100;
                const color = span.service.includes("backend") ? "bg-purple-500" : "bg-blue-500";

                return (
                    <div key={i} className="w-full">

                        <div className="text-sm text-gray-600 dark:text-gray-300 mb-1">
                            {span.name} <span className="text-xs text-gray-400"></span>
                        </div>
                        <div className="relative h-4 bg-gray-300/30 dark:bg-gray-700/30 rounded overflow-hidden">
                            <motion.div
                                className={`absolute h-full rounded ${color}`}
                                initial={{ width: 0 }}
                                animate={{
                                    width: `${widthPercent}%`,
                                    left: `${offsetPercent}%`
                                }}
                                transition={{ duration: 0.5, delay: i * 0.1 }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
