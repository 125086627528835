// components/InfraDiagram.tsx
import { motion } from "framer-motion";

export default function InfraDiagram({ className = "" }) {
    return (
        <div className={`w-full flex flex-col items-center ${className}`}>
            <h3 className="text-xl font-semibold text-white mb-4">Infra Flow</h3>
            <div className="grid grid-cols-5 gap-4 items-center text-center text-sm text-white">
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className="p-3 bg-gray-800 rounded-xl shadow"
                >
                    👤 Client
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="p-3 bg-gray-800 rounded-xl shadow"
                >
                    🌐 Ingress
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="p-3 bg-gray-800 rounded-xl shadow"
                >
                    🐍 Python API
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="p-3 bg-gray-800 rounded-xl shadow"
                >
                    📦 OTLP Exporter
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                    className="p-3 bg-gray-800 rounded-xl shadow"
                >
                    📊 LGTM Stack
                </motion.div>
            </div>

            <motion.div
                className="mt-4 text-gray-400 text-xs"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
            >
                Each request you make flows through this path.
            </motion.div>
        </div>
    );
}