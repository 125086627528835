import React, { useState, useEffect } from 'react';
import { ClockIcon } from '@heroicons/react/24/outline'; // if you're using Heroicons

function UptimeDisplay() {
    const [uptime, setUptime] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('/api/uptime')
            .then((res) => res.json())
            .then((data) => {
                setUptime(data.uptime);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Failed to fetch uptime:', err);
                setUptime('Error fetching uptime');
                setLoading(false);
            });
    }, []);

    return (
        <div className="absolute top-20 right-4 z-50 px-3 py-2 flex flex-col items-start gap-1 rounded-lg bg-white/10 backdrop-blur-md shadow-md ring-1 ring-white/20 hover:bg-white/20 transition text-green-100 min-w-[160px]">
            <div className="flex items-center gap-1 text-sm font-medium text-green-300">
                <ClockIcon className="w-4 h-4" />
                <span>Uptime:</span>
            </div>
            <p className="text-sm font-semibold text-green-200">
                {loading ? 'Getting uptime...' : uptime}
            </p>
        </div>
    );
}

export default UptimeDisplay;