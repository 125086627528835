// components/WarStoriesSection.tsx
import { motion } from "framer-motion";

const warStories = [
    {
        title: "🚫 Tempo Not Receiving Spans",
        date: "Mar 2025",
        description:
            "Frontend spans weren’t showing up in Tempo. After hours of debugging, I realized the browser was blocking the OTLP POST due to missing CORS headers. Fixed it with reverse proxy headers via Caddy and confirmed with ConsoleSpanExporter.",
    },
    {
        title: "🔍 LGTM Stack Vanishing Spans",
        date: "Mar 2025",
        description:
            "The LGTM service was working... until it wasn’t. Root cause was a mismatch between service port and targetPort in Kubernetes. Tempo wasn’t reachable despite correct API targets. Fixed by updating the Service YAML.",
    },
    {
        title: "🌀 Trace IDs Not Linking",
        date: "Mar 2025",
        description:
            "Frontend and backend had different trace IDs. Turns out I needed to propagate context correctly and explicitly inject headers. After fixing the span parenting, traces appeared as a single flow.",
    },
    {
        title: `🤯 <root span not yet received>`,
        date: "Mar 2025",
        description:
            "Tempo was displaying trace fragments with root span missing. Eventually traced it (ha) to the backend missing OTEL ASGI middleware. One line fix — hours of head scratching.",
    },
    {
        title: "🧵 FastAPI Span Context Confusion",
        date: "Mar 2025",
        description:
            "Tried to fetch the current span ID in the wrong part of the async context. Fixed by moving trace ID capture to a background task to give Tempo time to ingest and respond.",
    },
];

export default function WarStoriesSection() {
    return (
        <section id="hidden-scrollbar" className="w-full overflow-hidden px-6 py-20 flex flex-col items-center justify-center bg-auto">
            <motion.h2
                className="text-4xl md:text-6xl font-bold mb-12 text-white text-center"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                War Stories from the Cluster Trenches
            </motion.h2>

            <div className="w-full max-w-4xl space-y-6">
                {warStories.map((story, index) => (
                    <motion.div
                        key={index}
                        className="bg-white/5 border border-white/10 p-6 rounded-xl backdrop-blur-sm shadow-md text-white"
                        initial={{ opacity: 0, y: 30 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2, duration: 0.6 }}
                    >
                        <h3 className="text-xl font-semibold mb-1">{story.title}</h3>
                        <p className="text-sm text-gray-400 mb-2">{story.date}</p>
                        <p className="text-base text-gray-200 leading-relaxed">
                            {story.description}
                        </p>
                    </motion.div>
                ))}
            </div>
        </section>
    );
}
