// components/ResumeSection.tsx
import {useEffect, useState} from "react";
// @ts-ignore
import { Card, CardContent } from "./ui/card.tsx";
// @ts-ignore
import { Button } from "./ui/button.tsx";

export default function ResumeSection() {

    const [resumeContent, setResumeContent] = useState("");

    useEffect(() => {
        fetch("/resume.md")
            .then(res => res.text())
            .then(setResumeContent)
            .catch(() => setResumeContent("Failed to load resume."));
    }, []);

    return (
        <section
            className="w-full max-w-3xl mx-auto my-12"
             style={{
                     scrollSnapType: "y mandatory",
                     WebkitOverflowScrolling: "touch",
                 }}
        >

            <Card
                className="bg-white/60 dark:bg-white/5 backdrop-blur-sm border border-white/30 dark:border-white/10 shadow-lg"
                children={undefined} >
                <CardContent className="p-6 space-y-4" children={undefined}>
                    <div className="flex flex-col md:flex-row justify-between items-center mb-4 gap-4">
                        <h2 className="text-2xl font-bold">Resume</h2>
                        <div className="flex gap-3">
                            <Button>
                                <a href="https://github.com/seanrclayton" target="_blank" rel="noopener noreferrer">
                                    GitHub
                                </a>
                            </Button>
                            <Button>
                                <a href="https://www.linkedin.com/in/sean-clayton-2902886a/" target="_blank" rel="noopener noreferrer">
                                    LinkedIn
                                </a>
                            </Button>
                            <Button>
                                <a href="/resume.md" download>
                                    Download
                                </a>
                            </Button>
                        </div>
                    </div>

                    {/* Uptime badge placeholder */}
                    <div className="text-sm text-gray-500 dark:text-gray-400 italic mb-4">
                        Uptime: <span className="text-green-600 dark:text-green-400">✔ Live</span>
                        {/* Replace with badge or fetch status later */}
                    </div>

                    <div
                        className="prose dark:prose-invert max-w-none"
                        dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(resumeContent) }}
                    />
                </CardContent>
            </Card>
        </section>
    );
}

// Minimal Markdown to HTML conversion (for basic formatting)
function convertMarkdownToHtml(md: string): string {
    return md
        .replace(/^### (.*$)/gim, "<h3>$1</h3>")
        .replace(/^## (.*$)/gim, "<h2>$1</h2>")
        .replace(/^# (.*$)/gim, "<h1>$1</h1>")
        .replace(/\*\*(.*?)\*\*/gim, "<strong>$1</strong>")
        .replace(/\*(.*?)\*/gim, "<em>$1</em>")
        .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2' target='_blank'>$1</a>")
        .replace(/\n$/gim, "<br />");
}
