import { ButtonHTMLAttributes } from "react";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useRef } from 'react';


gsap.registerPlugin(useGSAP);

interface FancyButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    bounce?: boolean;
    children?: ButtonHTMLAttributes<HTMLButtonElement>
    className?: string;
}
export function Button({
                           children,
                           className = "",
                           bounce = false,
                           ...props
                       }: FancyButtonProps) {
    const boxRef = useRef(null);
    useGSAP(() => {
        if (bounce && boxRef.current) {
            gsap.to(boxRef.current, {
                y: 20, // Move down - for up
                duration: 0.5,
                ease: "bounce.out", // Apply bounce ease-out
                yoyo: true, // Make it go back and forth
                repeat: -1, // Repeat indefinitely

            });
        }}, []);


    return (
        <button
            ref={boxRef}
            className={`px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-xl font-medium transition ${className}`}
            {...props}
        >
            {children}
        </button>
    );
}
